








































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { PostsViewModel } from '../viewmodels/posts-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('./components/posts/post-list.vue'),
    'post-grid': () => import('./components/posts/post-grid.vue'),
  },
})
export default class Post extends Vue {
  @Provide() vm = new PostsViewModel()

  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#post') {
        setTimeout(() => {
          document.getElementById(`list-post`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true }) widthChange(value) {
    if (value <= 1480) {
      this.vm.changeDisplay('list')
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
