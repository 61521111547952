import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { PostsStore } from '@/stores/posts-store'
import { action, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
export class PostsViewModel {
  @observable posts?: PostsStore
  @observable userProfile?: ProfileModel
  @observable display: 'grid' | 'list' = 'list'
  private _disposers: IReactionDisposer[]

  constructor() {
    this.init()

    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
    ]
  }

  @action destroy() {
    this.posts?.destroy()
    this._disposers.map((d) => d())
  }

  @asyncAction *init() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        this.fetchPosts()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound changeDisplay(value: 'list' | 'grid') {
    this.display = value
  }

  @action.bound fetchPosts() {
    const filter = { _limit: 12, _start: 0, profile: this.userProfile?._id, status: 'public' }
    this.posts = new PostsStore(filter)
  }
}
